<template>
    <el-main>
        <div class="pagemain">
            <div class="searchdiv">
                <label class="title">{{$t('settings.department')}}</label>
                <div class="searchform">
                    <el-input v-model="searchinfo.dep_name" :placeholder="$t('settings.department')" :label="$t('settings.department')" clearable></el-input>
                    <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">{{$t('global.search')}}</el-button>
                </div>
                <div>
                    <el-button type="primary" @click="adddepartment('departmentform')" icon="el-icon-plus">{{$t('global.add')}} {{$t('settings.department')}}</el-button>
                </div>
            </div>
            <div class="pagecontent">
                <el-table
                        ref="multipleTable"
                        :data="departmentlist"
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="SelectionChange">
                    <el-table-column
                            :label="$t('settings.department')"
                            prop="dep_name">
                    </el-table-column>
                    <el-table-column
                            :label="$t('global.remarks')"
                            prop="remarks">
                    </el-table-column>
                    <el-table-column
                            :label="$t('global.state')"
                            prop="status">
                        <template slot-scope="scope">
                            <el-link :type="scope.row.status?'success':'danger'" @click="setenable(scope.row)">{{ scope.row.status?$t('global.enable'):$t('global.disable') }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('global.action')" fixed="right">
                        <template slot-scope="scope">
                            <el-link class="optlink"  @click="editdepartment(scope.row)">{{$t('global.edit')}}</el-link>
                            <el-link class="optlink"  @click="deldepartment(scope.row)">{{$t('global.delete')}}</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        @size-change="SizeChange"
                        @current-change="getdepartmentlist"
                        :current-page="searchinfo.page"
                        :page-sizes="PAGE.pageSizes"
                        :page-size="searchinfo.pagesize"
                        :layout="PAGE.layout"
                        :total="totalRows">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="departmentformdata.id?($t('global.add')+' '+$t('settings.department')):$t('global.edit')+' '+$t('settings.department')" :visible.sync="isshowdialog" :close-on-click-modal="false">
            <el-form :model="departmentformdata" :rules="rules" ref="departmentform" :show-message="false">
                <el-form-item :label="$t('settings.department')" label-width="120px" prop="dep_name" required>
                    <el-input v-model="departmentformdata.dep_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('global.state')" label-width="120px" prop="status">
                    <el-switch v-model="departmentformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
                </el-form-item>
                <el-form-item :label="$t('global.remarks')" label-width="120px" prop="remarks">
                    <el-input type="textarea" v-model="departmentformdata.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{$t('global.cancel')}}</el-button>
                <el-button type="primary" @click="dosubmit('departmentform')" :disabled="isbusy" :loading="isbusy">{{$t('global.submit')}}</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'department',
        components: {},
        data() {
            return {
                searchinfo: {
                    page:1,
                    pagesize: this.PAGE.pageSize,
                    dep_name: ''
                },
                departmentlist: [],
                multipleSelection: [],
                totalRows: 0,
                defaultdepartmentinfo: {
                    id: 0,
                    dep_name: '',
                    status: 1,
                    remarks: ''
                },
                departmentformdata: {},
                rules: {},
                isshowdialog: false,
                isbusy: false,
            }
        },
        methods: {
            SizeChange(val){
                this.searchinfo.pagesize=val;
                this.getdepartmentlist(1);
            },
            SelectionChange(val) {
                this.multipleSelection = val;
            },
            async dosearch(){
                this.getdepartmentlist(1);
            },
            async getdepartmentlist(page){
                this.searchinfo.page=page||this.searchinfo.page;
                this.isbusy = true;
                let res= await this.httpget('/system/department/index',this.searchinfo);
                if(res.code===0){
                    this.departmentlist=res.data.data;
                    this.totalRows = res.data.total;
                    this.isbusy = false;
                }else{
                    this.isbusy = false;
                }
            },
            editdepartment(item){
                this.departmentformdata=null;
                this.departmentformdata = this.cloneData(item);
                this.isshowdialog=true;
            },
            adddepartment(formName){
                this.departmentformdata = this.cloneData(this.defaultdepartmentinfo);
                this.isshowdialog=true;
                this.$nextTick(()=>{
                    this.$refs[formName].resetFields();
                });
            },
            dosubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit(){
                this.isbusy = true;
                let res= await this.httppost('/system/department/save',this.departmentformdata);
                if(res.code===0){
                    this.$message({type: 'success', message: res.msg});
                    this.getdepartmentlist(1);
                    this.departmentformdata={};
                    this.isshowdialog=false;
                    this.isbusy = false;
                }else{
                    this.isbusy = false;
                    this.$message({type: 'error', message: res.msg});
                }
            },
            async setenable(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/department/setenable',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        let idx=this.departmentlist.findIndex((value) => {
                            return value.id === item.id
                        })
                        if(idx!==-1){
                            this.departmentlist[idx].status = Math.abs(this.departmentlist[idx].status-1);
                        }
                        this.isbusy = false;
                    }else{
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            async deldepartment(item){
                this.$confirm('Are you sure to delete this item?').then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/department/delete',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        this.getdepartmentlist();
                        this.isbusy = false;
                    }else{
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            }
        },
        mounted() {
            this.getdepartmentlist(1);
        }
    }
</script>

<style>
    .uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
    .preview {
        width: 178px;
        height: 50px;
        display: block;
    }
</style>
